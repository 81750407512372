<template>
  <UseCodePanel/>
</template>

<script>
import UseCodePanel from "@/components/panels/giftcode/UseCodePanel";

export default {
  name: "UseCode",
  components: {UseCodePanel},
}
</script>